import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import Menu from "../components/menu"
import MenuSection from "../components/menuSection"

const Rentals = () => (
  <Layout>
    <Seo title="Rentals" />
    <Banner>
      <StaticImage
        src="../images/rentals_banner.jpg"
        alt='Banner image showing rental items, a wedding cake, and a "Mr & Mrs" sign'
        placeholder="blurred"
        layout="fullWidth"
      />
    </Banner>
    <Menu title="RENTALS">
      <MenuSection title="All Occasions">
        <ul>
          <li>Wedding</li>
          <li>Graduation</li>
          <li>Birthday</li>
          <li>Showers</li>
          <li>Gender Reveal</li>
          <li>Anniversary</li>
          <li>Theme Parties</li>
          <li>Milestone Moments</li>
          <li>Retirement</li>
        </ul>
      </MenuSection>
      <MenuSection title="Inventory">
        <ul>
          <li>Backdrops &amp; Event Props</li>
          <li>Arbors</li>
          <li>Linens</li>
          <li>Lanterns</li>
          <li>Glassware</li>
          <li>Custom Signs</li>
          <li>Serving &amp; Catering Ware</li>
          <li>Gift &amp; Cake Table Decor</li>
          <li>Tabletop Decor &amp; Centerpieces</li>
        </ul>
      </MenuSection>
    </Menu>
  </Layout>
)

export default Rentals
